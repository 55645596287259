<template>
  <div class="content_box">
    <div class="inner">
      <Card>
        <div class="title">
          <span>
            {{
              $t('register.finishPage.title', {
                individualUserName: individualUserName,
                platformName: $config.info.fullName
              })
            }}
          </span>
        </div>

        <div
          v-if="account && idPassed"
          v-html="$t('register.finishPage.contentDefault', { account: account, email: email })"
        ></div>

        <!-- id3沒過，有上傳文件，有帳號 -->
        <div v-else-if="account" v-html="$t('register.finishPage.contentWithoutId3WithAccount')"></div>

        <!-- id3沒過，有上傳文件，沒有帳號 -->
        <div v-else v-html="$t('register.finishPage.contentWithoutId3WithoutAccount')"></div>

        <!-- id3沒過，沒有上傳文件，有帳號 -->
        <!-- 跳轉HOME PAGE -->

        <!-- id3沒過，沒有上傳文件，沒有帳號 -->
        <!-- 跳轉HOME PAGE -->

        <div class="form_button">
          <router-link v-if="account" :to="{ name: 'depositFunds' }" class="el-button purple_button">
            {{ $t('register.btn.fundnow') }}
          </router-link>
          <router-link v-else :to="{ name: 'home' }" class="el-button purple_button">
            {{ $t('common.button.bkToHm') }}
          </router-link>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card';
import { apiID3Status } from '@/resource/register';
import { apiGetMetaTraderAccounts } from '@/resource';
import idPoaMixin from '@/mixins/idPoa';

export default {
  name: 'registerFinish',
  mixins: [idPoaMixin],
  components: { Card },
  data() {
    return {
      account: '',
      idPassed: false
    };
  },
  mounted() {
    apiID3Status().then(resp => {
      if (resp.data.code == 0 && resp.data.data) {
        this.idPassed = true;
        this.$store.commit('common/setPendingIDProofApproval', false);
        this.$store.commit('common/setRequireIDProof', false);
      }
      if (this.requireIDProof && !this.idPassed) this.$router.push('/home');
    });

    apiGetMetaTraderAccounts().then(resp => {
      if (resp.data && resp.data.length == 1 && resp.data[0].mt4_account) {
        this.account = resp.data[0].mt4_account;
        this.$store.commit('common/setOpenAccountAction', true);
      }
    });
  },
  computed: {
    individualUserName() {
      return this.$store.state.common.individualUserName;
    },
    email() {
      return this.$store.state.common.email;
    },
    requireIDProof() {
      return this.$store.state.common.requireIDProof;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/registerFinish.scss';
</style>
